import React from 'react';

import Home from './home/Home';

const CountrySelect: React.FunctionComponent<{}> = () => {
  return <Home />;
};


export default CountrySelect;
